
import * as echarts from "echarts";
import "echarts/lib/chart/line";
import "echarts/lib/component/tooltip";
import debounce from "lodash/debounce";
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";

const lightGray = "#00000010";
const mainGray = "#00000033";
const gray = "#00000082";

const yAxisStyle = {
    axisLine: {
        show: false,
        lineStyle: { color: gray },
    },
    splitLine: {
        lineStyle: { color: lightGray },
    },
    axisTick: {
        show: false,
    },
};

const xAxisStyle = {
    splitLine: {
        show: false,
    },
    axisLine: {
        lineStyle: { color: gray },
    },
    axisTick: {
        lineStyle: { color: mainGray },
    },
};

function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

// make sure all initial colors are the same
const BASIC_COLORS = ["#003f5c", "#2f4b7c", "#665191", "#a05195", "#d45087", "#f95d6a", "#ff7c43", "#ffa600"];

const BASIC_LINE_SERIES = {
    type: "line",
    showSymbol: false,
    hoverAnimation: false,
};

export default defineComponent({
    props: {
        xAxis: Object,
        yAxis: Object,
        tooltip: Object,
        series: Array,
        grid: Object,
        selected: Object,
    },
    data() {
        return { chart: null };
    },
    setup(props, ctx) {
        const chartRef = ref<HTMLElement>(null as any);
        const chart = ref<echarts.ECharts>(null as any);

        const self = {
            chartRef,
            resize: debounce(() => {
                chart.value?.resize();
            }, 0.001),
            get options() {
                return {
                    grid: {
                        x: 56,
                        y: 32,
                        x2: 32,
                        y2: 48,
                        ...(props.grid || {}),
                    },
                    tooltip: props.tooltip,
                    legend: {
                        type: "scroll",
                        orient: "horizontal",
                        right: 24,
                        ...(props.selected ? { selected: props.selected } : {}),
                        icon: "circle",
                    },
                    xAxis: { ...xAxisStyle, ...props.xAxis },
                    yAxis: { ...yAxisStyle, ...props.yAxis },
                    series: props.series
                        ? props.series
                              .map((s: any, i) => {
                                  return {
                                      // using default depmplate
                                      //color: BASIC_COLORS[i] || getRandomColor(),
                                      ...BASIC_LINE_SERIES,
                                      ...s,
                                  };
                              })
                              .sort((a, b) => (a.sort > b.sort ? -1 : 1))
                        : [],
                };
            },
        };

        function init() {
            try {
                chart.value && chart.value.setOption(self.options as any);
            } catch (e) {
                // todo: handle error
            }
        }

        watch(() => self.options, init);

        onMounted(() => {
            chart.value = echarts.init(chartRef.value as any);
            chart.value.on("legendselectchanged", (p: any) => ctx.emit("selectChange", p.name));
            window.addEventListener("resize", self.resize);
            init();
        });

        onUnmounted(() => {
            window.removeEventListener("resize", self.resize);
        });

        return self;
    },
});

/**
 * Common use collections
 *
 * @author Dan Feldman
 */
export class MultiMap<K, V> {
    private map: Map<K, Set<V>> = new Map<K, Set<V>>();

    public get(key: K): Set<V> {
        return this.map.get(key) ?? new Set<V>();
    }

    public put(key: K, value: V) {
        if (this.map.has(key)) {
            (this.map.get(key) ?? new Set<V>()).add(value);
        } else {
            this.map.set(key, new Set<V>([value]));
        }
    }

    public isEmpty(): boolean {
        return this.map.size == 0;
    }

    [Symbol.iterator](): IterableIterator<[K, Set<V>]> {
        return this.map.entries();
    }

    entries(): IterableIterator<[K, Set<V>]> {
        return this.map.entries();
    }

    keys(): Iterable<K> {
        return this.map.keys();
    }

    values(): Iterable<Set<V>> {
        return this.map.values();
    }
}

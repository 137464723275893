import { setBlockTracking as _setBlockTracking, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _cache[0] || (
    _setBlockTracking(-1),
    _cache[0] = _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.chart),
      ref: "chartRef"
    }, null, 2),
    _setBlockTracking(1),
    _cache[0]
  )
}
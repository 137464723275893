
import { defineComponent, PropType, ref } from "vue";
import { Tab } from "./types/tab";

export default defineComponent({
    emits: ["select"],
    props: {
        currentTab: Object as PropType<Tab>,
        tabs: Array as PropType<Array<Tab>>,
    },
    data(){
        return {
            currentTabModel: this.currentTab?.title
        }
    },
    watch:{
        currentTab:{
            handler(newTab){
                this.currentTabModel = newTab.title
            },
            deep: true
        }
    }
});

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: "phase",
  style: {"padding":"0 54px 0 20px"}
}
const _hoisted_2 = {
  style: {"padding":"0 24px 0 54px"},
  key: "zoom"
}
const _hoisted_3 = { style: {"overflow-y":"auto","position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatusGannt = _resolveComponent("StatusGannt")!
  const _component_TimeZoom = _resolveComponent("TimeZoom")!
  const _component_LoadingDataFailure = _resolveComponent("LoadingDataFailure")!
  const _component_Dashboard = _resolveComponent("Dashboard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.jobPhasesCache.data)
        ? (_openBlock(), _createBlock(_component_StatusGannt, {
            key: 0,
            height: "96",
            data: _ctx.jobPhasesCache.data
          }, null, 8, ["data"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TimeZoom, {
        start: _ctx.start,
        end: _ctx.end,
        dirty: _ctx.dirty,
        "onUpdate:dirty": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dirty) = $event)),
        from: _ctx.from,
        "onUpdate:from": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.from) = $event)),
        to: _ctx.to,
        "onUpdate:to": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.to) = $event))
      }, null, 8, ["start", "end", "dirty", "from", "to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showDataFailure)
        ? (_openBlock(), _createBlock(_component_LoadingDataFailure, {
            key: 0,
            onRefresh: _ctx.refresh,
            float: ""
          }, null, 8, ["onRefresh"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.graphContainer)
      }, [
        _createVNode(_component_Dashboard, { charts: _ctx.gpu }, null, 8, ["charts"]),
        _createVNode(_component_Dashboard, { charts: _ctx.gpuMemory }, null, 8, ["charts"]),
        _createVNode(_component_Dashboard, { charts: _ctx.cpu }, null, 8, ["charts"]),
        _createVNode(_component_Dashboard, { charts: _ctx.memory }, null, 8, ["charts"])
      ], 2)
    ])
  ], 64))
}

import ProcessBar from "@/core-ui/ProgressBar.vue";
import Loading from "@/core-ui/Loading.vue";

import LineChart from "./LineChart.vue";
import PercentageChart from "./PercentageChart.vue";

import { UsingCacheFetch } from "../data-grid/compositions";

import { ChartTypes } from "./types/chart";
import { isEmpty } from "./utils";
import { defineComponent, PropType } from "vue";

const charts = {
    [ChartTypes.Line]: LineChart,
    [ChartTypes.Percentage]: PercentageChart,
};

export default defineComponent({
    components: { ProcessBar, Loading },
    props: {
        label: String,
        meta: Object,
        cache: Object as PropType<UsingCacheFetch<any>>,
    },
    setup: (props: any) => {
        if (props.cache && props.cache.state == "idle") {
            props.cache.fetch();
        }

        return {
            Comp: (charts as any)[props.meta!.type],
            get isUpdating() {
                return props.cache.state == "update";
            },

            get isEmpty() {
                return isEmpty(props.cache.data);
            },

            get isLoading() {
                return props.cache.state == "initialize";
            },
        };
    },
});

import { computed, onMounted, onUnmounted, ref } from "vue";
import { Range } from "@/components/deployments/utils";

export function useSize(): any {
    const r = ref<Element>();
    const hook = ref(1);

    const w = computed(() => {
        hook.value;
        return r.value?.clientWidth || 100;
    });
    const h = computed(() => {
        hook.value;
        return r.value?.clientHeight || 100;
    });

    function update() {
        hook.value++;
    }

    onMounted(() => window.addEventListener("resize", update)),
        onUnmounted(() => window.removeEventListener("resize", update));

    return {
        get ref() {
            return r.value;
        },
        set ref(v) {
            r.value = v;
        },
        get w() {
            return w.value;
        },
        get h() {
            return h.value;
        },
    };
}

export function convertRange(r1: Range, r2: Range, value: number): number {
    return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

export function inRange(value: number, min?: number, max?: number): boolean {
    return (min == undefined || min <= value) && (max == undefined || max >= value);
}

export function useSVGDragable({
    def = [],
    min = [],
    max = [],
}: {
    def?: Partial<Range>;
    min?: Partial<Range>;
    max?: Partial<Range>;
}): any {
    const x = ref(def[0] || 0);
    const y = ref(def[1] || 0);

    const moving = ref(false);

    function start() {
        moving.value = true;
    }

    function update(r: Partial<Range>) {
        if (!moving.value) {
            return;
        }
        if (r[0] != undefined && inRange(r[0], min[0], max[0])) {
            x.value = r[0];
        }
        if (r[1] != undefined && inRange(r[1], min[1], max[1])) {
            y.value = r[1];
        }
    }

    function stop() {
        moving.value = false;
    }

    return {
        get moving() {
            return moving.value;
        },
        get x() {
            return x.value;
        },
        get y() {
            return y.value;
        },
        start,
        stop,
        update,
        updateFromSVGMouseEvent(e: { layerX: number; layerY: number }) {
            update([e.layerX, e.layerY]);
        },
        startFromSVGMouseEvent(e: { layerX: number; layerY: number }) {
            start();
            update([e.layerX, e.layerY]);
        },
    };
}

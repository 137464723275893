import prometheusService from "./prometheus-service";
import { MultiMap } from "@/core-ui/helpers/collections";
import apiClient from "./api-client";
import podsService from "./pods-service";
import { get } from '../core-ui/forms/components/Toggle/utils';
import deploymentsService from "./deployments-service";

type GpuMetric = {
    id: string,
    gpu: string,
    node: string,
    utilization: string,
    usedGpuMemory: string,
    totalGpuMemory: string,
    gpuIdleTime: string,
};

class GpusService {
    getListOfQueryResultMetadata(nodeId: string) {
        const promQueries = {
            utilization: `(sum(runai_gpu_utilization_per_gpu{node="${nodeId}"}) by (node, gpu)) or (sum(runai_node_gpu_utilization{node="${nodeId}"}) by (node, gpu))`,
            usedGpuMemory: `(sum(runai_gpu_memory_used_mebibytes_per_gpu{node="${nodeId}"} * 1024 * 1024) by (node, gpu)) or (sum(runai_node_gpu_used_memory_excluding_mig{node="${nodeId}"} * 1024 * 1024) by (node, gpu))`,
            totalGpuMemory: `(sum(runai_gpu_memory_total_mebibytes_per_gpu{node="${nodeId}"} * 1024 * 1024) by (node, gpu)) or (sum(runai_node_gpu_total_memory_excluding_mig{node="${nodeId}"} * 1024 * 1024) by (node, gpu))`,
            gpuIdleTime: `(sum(runai_gpu_idle_seconds_per_gpu{node="${nodeId}"}) by(node, gpu)) or (sum(time()-runai_node_gpu_last_not_idle_time{node="${nodeId}"}) by (node, gpu))`,
        };

        return prometheusService.getFilteredResultMultipleQueries(promQueries, ["node", "gpu"]).then((list) =>
            list.map((item) => {
                // Temporary - until the "general" request from prometheus will have an alternative.
                item.node = nodeId;
                return item;
            }),
        );
    }

    async getByNodeIdAndPodGroupId(nodeIds: string, podGroupId: string, deployment: Object) {
        if (podGroupId) {
            return await this.getJobAssignedGPUs(podGroupId);
        }

        if (deployment) {
            return await this.getDeploymentAssignedGPUs(deployment);
        }

        const nodeIdsSplit = nodeIds.split(", ");
        let dataOfAllNodes: any[] = [];
        for (const nodeId of nodeIdsSplit) {
            let data = await gpusService.getListOfQueryResultMetadata(nodeId);
            data = data.map((r) => ({ ...r, gpu: Number(r.gpu) }));
            dataOfAllNodes = dataOfAllNodes.concat(data);
        }
        return dataOfAllNodes;
    }

    private async getJobAssignedGPUs(podGroupId: string) {
        const jobGpusByNode = await this.getAllocatedGpusByJob(podGroupId);
        
        const nodeGpuMapping: MultiMap<string, number> = new MultiMap<string, number>();
        for (const nodeGpu of jobGpusByNode) {
            nodeGpuMapping.put(nodeGpu.node, nodeGpu.gpu);
        }

        return this.getGpusMetrics(nodeGpuMapping);
    }

    private getAllocatedGpusByJob(podGroupUuid: string) {
        return prometheusService.getFilteredResultMultipleQueries(
            {
                general: `runai_gpu_utilization_per_pod_per_gpu{pod_group_uuid="${podGroupUuid}"} or on(pod_group_uuid) ((runai_utilization_full_gpu_jobs{pod_group_uuid="${podGroupUuid}"}) or (runai_utilization_shared_gpu_jobs{pod_group_uuid="${podGroupUuid}"}))`,
            },
            ["gpu", "node"],
        );
    }

    private async getDeploymentAssignedGPUs(deployment: any): Promise<GpuMetric[]> {
        const pods = await podsService.get("", deployment.id, deployment.clusterUUID)
        const podNamesRegex = pods.map(pod => pod.name).join("|");
        const podInfos = await prometheusService.getFilteredResultMultipleQueries(
            {
                general: `runai_gpu_utilization_per_pod_per_gpu{pod_name=~"${podNamesRegex}", pod_namespace="${deployment.namespace}"} or (runai_pod_phase_with_info{pod_name=~"${podNamesRegex}", pod_namespace="${deployment.namespace}", gpu!=""} or label_replace(runai_gpu_utilization_with_pod_info{pod_name=~"${podNamesRegex}", pod_namespace="${deployment.namespace}", gpu!=""}, "node_name", "$1", "node", "(.*)"))`,
            },
            ["gpu", "node"],
        );

        const nodeGpus = new MultiMap<string, number>();
        for (const podInfo of podInfos) {
            nodeGpus.put(podInfo.node, podInfo.gpu);
        }

        return this.getGpusMetrics(nodeGpus);
    }

    private async getGpusMetrics(nodeGpus: MultiMap<string, number>): Promise<GpuMetric[]> {
        let gpusMetrics: GpuMetric[] = [];
        for (const [nodeName, gpus] of nodeGpus) {
            gpusMetrics = (await this.getListOfQueryResultMetadata(nodeName))
                .filter(({ gpu }) => gpus.has(gpu))
                .concat(gpusMetrics);
        }

        return gpusMetrics;
    }
}

const gpusService = new GpusService();
export default gpusService;

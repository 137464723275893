export type DataSeries = Array<[number, number]>;

export function toTimestamp(val: any): number | undefined {
    return val ? val * 1000 : undefined;
}

export function toDate(val: any): Date | undefined {
    return val ? new Date(Number(val)) : undefined;
}
export function prepareRangeRow([date, num]: any) {
    return [date * 1000, Number(num)];
}

export function getStep(start: number, end: number, optimalSamples = 200): number {
    // divided the distance of the start and end and round it to a number divided by 100
    const range = end / 1000 - start / 1000;
    return Math.max(5, Math.floor(range / optimalSamples / 100) * 100);
}

export function avg(val: DataSeries) {
    const data = val.map(([_, v]) => v).filter((v) => typeof v == "number");
    return data.reduce((n: number, v) => n + v, 0) / data.length;
}

export function extractLabels(labels: Record<string, string>) {
    const cache = new Map();
    return (id: string, key: string, row: any, metric: any, value: any) => {
        for (const label in labels) {
            const labelKey = labels[label];
            const val = metric[label];
            if (!val) return;

            let rowCache = cache.get(id);
            if (!rowCache) {
                rowCache = {};
                cache.set(id, rowCache);
            }

            if (!row[labelKey]) {
                row[labelKey] = val;
                rowCache[labelKey] = new Set([val]);
            } else if (!rowCache[labelKey].has(val)) {
                row[labelKey] += ` ,${val}`;
                rowCache[labelKey].add(val);
            }
        }
    };
}

export function normalizeDataset(start: number, end: number, step: number, value: number = null as any) {
    return (data: DataSeries): DataSeries => {
        if (!data.length) {
            return data;
         }
        const result: DataSeries = [];

        //
        step = step * 3000 + 100;
        if (data[0][0] > start + step) {
            result.push([start, value]);
        }

        let x_limit = data[0][0] + step;
        let gapCount = 0;
        let x_limit_2 = x_limit;
        for (const [x, y] of data) {
            if (x_limit_2 < x) {
                gapCount++;
            }
            x_limit_2 = x + step;
        }
        let previous_x = null;
        // do nothing if the gap is so broken
        if (gapCount < data.length / 5) {
            for (const [x, y] of data) {
                if (x_limit < x) {
                    result.push([x_limit, value]);
                }
                if (x_limit < x - step) {
                    result.push([x - step, value]);
                }
                // ensure the timeline order
                if (previous_x == null || previous_x <= x) {
                    result.push([x, y]);
                    previous_x = x;
                }
                x_limit = x + step;
            }
        }

        if (result.length && result[result.length - 1][0] < end - step) {
            result.push([end, value]);
        }
        return result;
    };
}

export function metricsFirstValues(data: any): Array<[number, number]> {
    return (data.data.result ? data.data.result[0]?.values ?? [] : []).map(prepareRangeRow) as any;
}

import prometheusService from "./prometheus-service";
import apiClient from "./api-client";
import { DataGridColumn } from "@/core-ui/types/column";
import { LabelType, LabelTypeOrder } from "@/types/label-type";
import { Job } from "@/types/job";
import { filterAPIToUrlQuery, filterQueryToUrlQuery } from "@/core-ui/data-grid/utils";
import { FilterAPI } from "@/core-ui/data-grid/compositions";
import * as prom from "@/core-ui/helpers/prometheus";
import settingStore, { SettingKeys } from "@/stores/setting-store";

const DEFAULT_METRIC_COLUMN_WIDTH_ = 160;
const DEFAULT_PARAMETER_COLUMN_WIDTH_ = 200;
const DEFAULT_ALIGN_RIGHT = true;

interface ColumnsMapper {
    [key: string]: DataGridColumn;
}

function metricConverter(value: null | number) {
    if (value == null) {
        return null as any as string;
    }

    return (Math.round(Number(value) * 10000) / 10000).toString();
}

class JobsService {
    getJobs(clusterUUID: string, filter: FilterAPI, existsInCluster?: boolean): Promise<{ data: Array<Job>; length: number; columns: any }> {
        // map UI column keys into the api column keys:

        if(!filter.query.jobType) filter.query["jobType"] = "Train|Interactive";
        return this.getWorkloads(clusterUUID, filter, existsInCluster);
    }

    getDeletedJobs(clusterUUID: string, filter: FilterAPI): Promise<{ data: Array<Job>; length: number; columns: any }> {
        return this.getJobs(clusterUUID, filter, false)
    }

    getWorkspaces(clusterUUID: string, filter: FilterAPI): Promise<{ data: Array<Job>; length: number; columns: any }> {
        // map UI column keys into the api column keys:
        filter.query["jobType"] = "Interactive";
        return this.getWorkloads(clusterUUID, filter);
    }

    private getWorkloads(clusterUUID: string, filter: FilterAPI, existsInCluster?: boolean) {
        const params = filter ? filterAPIToUrlQuery(filter) : "";
        const url = JobsService.getDataUrlByType(clusterUUID, params, existsInCluster);

        return Promise.all([
            apiClient.asyncRequestWithResponse<Array<Job>>(url, "GET"),
            this.getJobsPrometheusData(clusterUUID),
            this.getJobsPrometheusDynamicData(clusterUUID),
            this.getWorkloadCount(clusterUUID, filterQueryToUrlQuery(filter.query), existsInCluster),
        ])
            .then(([jobs, jobsPromMap, podsPromMap, length]) => {
                const data = (jobs as Array<Job>).map((job) => {
                    const podPromData = (podsPromMap as any)[job.latestPod?.podId] || {};
                    const jobPromData = (jobsPromMap as any)[job.podGroupId] || {};
                    this.fixJobDynamicData(job, {
                        ...jobPromData,
                        ...podPromData,
                        dynamicData: {
                            ...(jobPromData.dynamicData || {}),
                            ...(podPromData.dynamicData || {}),
                        },
                    });
                    this.fillMissingFields(job);
                    return job;
                });

                return {
                    columns: this.getDynamicColumns(data as any),
                    data,
                    length,
                };
            })
            .catch((reason) => {
                //oops should have done a try/catch around each column so you don't mess up the entire table over errors
                return reason.reject;
            });
    }

    getWorkloadCount(clusterUUID: string, filter?: string, existsInCluster?: boolean): Promise<number> {
        const params = [];

        if (filter) {
            params.push(`filter=${filter}`);
        }

        const url = JobsService.getCountUrlByType(clusterUUID, params.join('&'), existsInCluster);

        return apiClient
            .asyncRequestWithResponse<{ count: number }>(url,"GET")
            .then((res) => res.count || 0)
            .catch((err: Error) => {
                return 0;
            });
    }

    private static getDataUrlByType(clusterUUID: string, queryParams: string, existsInCluster?: boolean): string {
        if (existsInCluster === false) {
            return `${JobsService.baseJobsUrl(clusterUUID)}/deleted/${queryParams}`;
        }
        return `${JobsService.baseJobsUrl(clusterUUID)}/${queryParams}`;
    }

    private static getCountUrlByType(clusterUUID: string, queryParams: string, existsInCluster?: boolean): string {
        if (existsInCluster === false) {
            return `${JobsService.baseJobsUrl(clusterUUID)}/deleted/count?${queryParams}`;
        }
        return `${JobsService.baseJobsUrl(clusterUUID)}/count?${queryParams}`;
    }

    private static baseJobsUrl(clusterUUID: string) {
        return `v1/k8s/clusters/${clusterUUID}/jobs`;
    }

    getListOfQueryResultValues(results?: Array<any>) {
        if (!results) {
            return [];
        }

        return results.map((item: Job) => {
            for (const key in item.dynamicData) {
                const data: any = item.dynamicData[key];
                item[key] = data?.value ? data.value : data;
            }
            return this.fixRelevantPropertiesForItem(item);
        });
    }

    getDynamicColumns(list?: Array<Job>): ColumnsMapper {
        if (!list) {
            return {};
        }

        return list.reduce((result: ColumnsMapper, item: Job) => {
            if (!item.dynamicData) {
                return result;
            }

            let itemColumn, itemValue, isPromData, key, isMetric;
            for (key in item.dynamicData) {
                itemColumn = item.dynamicData[key];
                isPromData = !!(itemColumn as any)?.value;
                if (isPromData) {
                    itemValue = (itemColumn as any).value;
                    isMetric = (itemColumn as any).type === "metric";
                } else {
                    itemValue = itemColumn;
                    isMetric = typeof itemColumn === "number";
                }

                if (!result[key]) {
                    result[key] = {
                        key,
                        label: key,
                        dataKey: key,
                        dataTransform: isMetric ? metricConverter : undefined,
                        display: {
                            table: {
                                width: isMetric ? DEFAULT_METRIC_COLUMN_WIDTH_ : DEFAULT_PARAMETER_COLUMN_WIDTH_,
                                alignedRight: isNaN(itemValue) ? false : DEFAULT_ALIGN_RIGHT,
                            },
                        },
                    };
                }
            }
            return result;
        }, {});
    }

    metrics = {
        getCompareRange(
            podUUID: string,
            start?: Date | number,
            end?: Date | number,
            xAxisMetric = "epoch",
            yAxisMetric = "accuracy",
        ) {
            const queries = [
                `reporter_push_gateway_metric_${xAxisMetric}{metric_name="${xAxisMetric}",podUUID="${podUUID}",push_gateway_type="metric"} `,
                `reporter_push_gateway_metric_${yAxisMetric}{metric_name="${yAxisMetric}",podUUID="${podUUID}",push_gateway_type="metric"} `,
            ];
            return prometheusService.getMultipleRangeQueries(queries, start, end, 100).then((results: any) => {
                const xAxisResult = results[0].result[0];
                const yAxisResult = results[1].result[0];
                const xAxis = xAxisResult ? xAxisResult.values : [];
                const yAxis = yAxisResult ? yAxisResult.values : [];

                return xAxis
                    .map(([_, x]: any, index: number) => {
                        const yMetric = yAxis[index];
                        return [x, yMetric ? Number(yMetric[1]) : 0];
                    })
                    .reduce((res: any, row: any) => {
                        const last = res[res.length - 1];
                        if (last && last[0] === row[0]) {
                            last[1] = row[1];
                        } else {
                            res.push(row);
                        }
                        return res;
                    }, []);
            });
        },
        getJobPhase(podUUID: string, start?: Date | number, end?: Date | number, step = 100) {
            return prometheusService
                .rangeQueryExtended({
                    query: `runai_pod_group_phase_with_info{pod_group_uuid="${podUUID}"}==1`,
                    start,
                    end,
                    step,
                })
                .then((d) => {
                    const data = {
                        pending: [] as any,
                        running: [] as any,
                        suspended: [] as any,
                        swapped: [] as any,
                    };
                    const defaultInterval = d[1];
                    for (const { metric, values } of d[0].data.result) {
                        if (!values[0][0]) {
                            continue;
                        }

                        // get ranges when the status was true
                        const ranges = [] as any;
                        if (values.length == 1) {
                            const range = [prom.toTimestamp(values[0][0]), prom.toTimestamp(values[0][0])];
                            // create minimum interval if the values are equals
                            range[1]! += 2000;
                            ranges.push(range);
                        } else {
                            let rangeStart = 0;
                            for (let i = 0; i < values.length - 1; i++) {
                                if (values[i + 1][0] - values[i][0] == defaultInterval) {
                                    if (rangeStart == 0) {
                                        rangeStart = values[i][0];
                                    }
                                    continue;
                                }

                                // range between values is bigger then default interval - push range till now
                                if (rangeStart != 0) {
                                    const range = [prom.toTimestamp(rangeStart), prom.toTimestamp(values[i][0])];
                                    ranges.push(range);
                                    rangeStart = 0;
                                }
                            }

                            if (rangeStart != 0) {
                                const range = [
                                    prom.toTimestamp(rangeStart),
                                    prom.toTimestamp(values[values.length - 1][0]),
                                ];
                                ranges.push(range);
                            }
                        }

                        if (metric.phase == "Running") {
                            data.running = data.running.concat(ranges);
                        } else if (metric.phase == "Swapped") {
                            data.swapped = data.swapped.concat(ranges);
                        } else if (metric.phase == "Suspended") {
                            data.suspended = data.suspended.concat(ranges);
                        } else {
                            data.pending = data.pending.concat(ranges);
                        }
                    }
                    return data;
                });
        },
        getGPUsUtils(podUUID: string, start?: Date | number, end?: Date | number, step = 100) {
            const queries = [
                `(sum by (pod_group_uuid, node_name, gpu) (label_replace(runai_gpu_utilization_per_pod_per_gpu{pod_group_uuid="${podUUID}"}, "node_name", "$1", "node", "(.+)"))) or (sum by (pod_group_uuid, node_name, gpu) (runai_utilization_full_gpu_jobs{pod_group_uuid="${podUUID}"}) or sum by (node_name, gpu) (runai_utilization_shared_gpu_jobs{pod_group_uuid="${podUUID}"}))`,
                `avg((runai_gpu_utilization_per_workload{job_uuid="${podUUID}"})) or (avg(runai_utilization_full_gpu_jobs{pod_group_uuid="${podUUID}", node_name!=""}) or avg(runai_utilization_shared_gpu_jobs{pod_group_uuid="${podUUID}"}))`,
            ];

            return prometheusService.getMultipleRangeQueries(queries, start, end, step).then((results: any) => {
                return results.reduce(
                    (flat: any, data: any) => {
                        let arr = data.result || [];
                        if (arr && data.query === queries[0]) {
                            arr = arr
                                .filter(
                                    (series: any) =>
                                        series.metric.gpu != undefined && series.metric.node_name != undefined,
                                )
                                .reduce((o: any, s: any) => {
                                    o[`${s.metric.node_name}(${s.metric.gpu})`] = s.values;
                                    return o;
                                }, flat);
                        } else {
                            flat["average"] = arr[0]?.values || [];
                        }
                        return flat;
                    },
                    { average: [] },
                );
            });
        },
        getCPUsUtils(podUUID: string, start?: Date | number, end?: Date | number, step = 100) {
            return prometheusService.rangeQuery({
                query: `runai_job_cpu_usage{pod_group_uuid="${podUUID}"}`,
                start,
                end,
                step,
            });
        },
        getGpusMemoryUtils(podUUID: string, start?: Date | number, end?: Date | number, step = 100) {
            return prometheusService.rangeQuery({
                query: `sum(runai_gpu_memory_used_mebibytes_per_workload{job_uuid="${podUUID}"} * 1024 * 1024) or sum(runai_pod_group_used_gpu_memory{pod_group_uuid="${podUUID}"} * 1024 * 1024)`,
                start,
                end,
                step,
            });
        },
        getMemoryUtils(podUUID: string, start?: Date | number, end?: Date | number, step = 100) {
            return prometheusService.rangeQuery({
                query: `runai_job_memory_used_bytes{pod_group_uuid="${podUUID}"}`,
                start,
                end,
                step,
            });
        },
    };

    private fillMissingFields(job: Job) {
        // The fields below the if with wasClusterUpdated were added with the HPO feature.
        // this part can be removed after all cluster were upgraded.
        const wasClusterUpdated = job.workloadKind != null;
        if (job.workloadKind === "MPIJob") {
            job.isDistributed = true;
        } else {
            job.isDistributed = false;
        }

        if (wasClusterUpdated) {
            // No need to backward compatibility
            return;
        }

        if (job.status === LabelType.PENDING) {
            job.pending = 1;
        } else {
            job.pending = 0;
        }
        if (job.status === LabelType.RUNNING) {
            job.running = 1;
        } else {
            job.running = 0;
        }

        job.parallelism = 1;
        job.completions = 1;
        if (job.status === LabelType.ERROR) {
            job.failed = 7;
        } else {
            job.failed = 0;
        }

        if (job.status === LabelType.SUCCESS) {
            job.succeeded = 1;
        } else {
            job.succeeded = 0;
        }

        if (job.status === LabelType.RUNNING) {
            job.currentAllocatedGPUsMemory = job.totalRequestedGPUs;
        } else {
            job.currentAllocatedGPUsMemory = 0;
        }

        if (job.status === LabelType.RUNNING) {
            job.currentAllocatedGPUs = job.totalRequestedGPUs;
        } else {
            job.currentAllocatedGPUs = 0;
        }

        if (job.status === LabelType.PENDING || job.status === LabelType.RUNNING) {
            job.currentRequestedGPUs = job.totalRequestedGPUs;
        } else {
            job.currentRequestedGPUs = 0;
        }

        if (job.jobType === "train") {
            job.workloadKind = "Job";
        } else {
            job.workloadKind = "StatefulSet";
        }
    }

    private fixJobDynamicData(job: Job, promData: any = {}) {
        // adding db dynamic data
        job.runaiCliCommand = job.dynamicData?.RunaiCliCommand || "-";

        const backwardCompatibleRequestedGPUs = job.dynamicData?.RequestedGPUs as number;
        if (!job.totalRequestedGPUs && backwardCompatibleRequestedGPUs > 0) {
            job.totalRequestedGPUs = backwardCompatibleRequestedGPUs;
        }

        // override the dynamic data with prom dynamic data
        Object.assign(job, promData);
    }

    private getJobsPrometheusData(clusterId?: string) {
        const clusterFilter = clusterId ? `{clusterId="${clusterId}"}` : "";

        const promQueries: { [key: string]: string } = {
            gpusUtilization: `(avg(runai_gpu_utilization_per_pod_per_gpu${clusterFilter}) by(pod_group_uuid)) or (sum by (pod_group_uuid) (runai_pod_group_gpu_utilization${clusterFilter})
                / on (pod_group_uuid)
                (count(runai_pod_group_gpu_utilization${clusterFilter}) by (pod_group_uuid)
                or
                sum(runai_utilization_shared_gpu_jobs${clusterFilter}) by (pod_group_uuid)))`,
            // currentRequireCPUs: `runai_active_job_cpu_requested_cores${clusterFilter}`,
            // currentLimitCPUs: `runai_active_job_cpu_limits${clusterFilter}`,
            usedCPUs: `runai_job_cpu_usage${clusterFilter}`,
            // currentRequireMemory: `runai_node_requested_memory_bytes${clusterFilter}`,
            // currentMemoryLimit: `runai_active_job_memory_limits${clusterFilter}`,
            usedMemory: `runai_job_memory_used_bytes${clusterFilter}`,
            usedGpuMemory: `(sum(runai_gpu_memory_used_mebibytes_per_pod_per_gpu${clusterFilter} * 1024 * 1024) by (pod_group_uuid, job_uuid)) or (sum(runai_pod_group_used_gpu_memory${clusterFilter} * 1024 * 1024) by (pod_group_uuid, job_uuid))`,
            swapCPUMemory: `runai_pod_group_swap_memory_used_bytes${clusterFilter}`,
            // general_h: `sum(last_created_pod_of_job${clusterFilter}) by (pod_group_name, pod_group_uuid, requested_gpus)`
        };
        return prometheusService.getMetadataResultMultipleQueries(promQueries, "pod_group_uuid").catch((err: Error) => {
            return {};
        });
    }

    private getJobsPrometheusDynamicData(clusterId?: string) {
        const clusterFilter = clusterId ? `, clusterId="${clusterId}"` : "";

        const promQueries: { [key: string]: string } = {
            //general_h: `sum(last_created_pod_of_job${clusterFilter}) by (pod_group_name, pod_group_uuid, requested_gpus)`,
            allReporterPushGatewayMetrics: `{push_gateway_type="metric"${clusterFilter}}`,
            allReporterPushGatewayParameters: `{push_gateway_type="parameter"${clusterFilter}}`,
        };

        return prometheusService.getMetadataResultMultipleQueries(promQueries, "podUUID").catch((_) => ({}));
    }

    private fixRelevantPropertiesForItem(item: Job) {
        // if (item.status !== LabelType.RUNNING && item.nodeId) {
        //     item.nodeId = `(${item.nodeId})`;
        // }

        const run_progress = ((Number(item.epoch) + 1) / Number(item.overall_epochs)) * 100;
        const status: LabelType = item.status;
        item.training_lib = (LabelTypeOrder[status] || 9) + "|" + (status || "");
        if (!isNaN(run_progress)) {
            item.run_progress = run_progress;
            item.training_lib += "|" + run_progress;
        }

        return item;
    }

    /**
     * delete job somehow
     * @param job
     */
    deleteJob(job: any) {
        return Promise.resolve(job);
    }
}

const jobsService = new JobsService();
export default jobsService;

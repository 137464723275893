import { DataGridColumn } from "@/core-ui/types/column";
import ProgressLabel from "@/core-ui/ProgressLabel.vue";
import MemoryLabel from "@/core-ui/MemoryLabel.vue";
import { idleGpuDurationFormat } from "@/core-ui/helpers/data-formats";
import {AddDefaultSort, createModelMeta, DataGridModelProps} from "@/core-ui/data-grid/compositions";
import gpusService from "@/services/gpus-service";
import {computed} from "vue";

export const columns: DataGridColumn[] = [
    {
        key: "gpu",
        label: "GPU",
        dataKey: "gpu",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 107,
            },
        },
    },
    // {
    // 	key: 'gpu-type',
    // 	label: 'GPU Type',
    // 	dataKey: 'gpu_type',
    // 	sortable: true,
    // 	searchable: true,
    // 	display: {
    //      table : {
    // 		width: 107
    // 	}},
    // },
    {
        key: "node",
        label: "Node",
        dataKey: "node",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 200,
            },
        },
    },
    {
        key: "utilization",
        label: "Utilization",
        dataKey: "utilization",
        sortable: true,
        searchable: true,
        display: {
            component: ProgressLabel,
            table: {
                width: 160,
            },
        },
    },
    {
        key: "gpu-memory",
        label: "GPU Memory",
        dataKey: "totalGpuMemory",
        sortable: true,
        searchable: true,
        display: {
            component: MemoryLabel,
            table: {
                width: 135,
            },
        },
    },
    {
        key: "used-gpu-memory",
        label: "Used GPU Memory",
        dataKey: "usedGpuMemory",
        sortable: true,
        searchable: true,
        display: {
            component: MemoryLabel,
            table: {
                width: 170,
            },
        },
    },
    {
        key: "gpu-idle-time",
        label: "GPU Idle Time",
        dataKey: "gpuIdleTime",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 150,
            },
        },
        dataTransform: idleGpuDurationFormat,
    },
];

export interface Gpu {
    gpu: number;
    gpu_type: string;
}

export type ModalType = Gpu;
const type = "gpu";
export const meta = createModelMeta<ModalType>({
    icon: `raicon-${type}`,
    type,
    display: (item) => item.gpu_type,
});

export const createModelProps = (props: {
    dataKey: string;
    nodeId: string;
    podGroupId: string;
    deployment: Object;
}): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => AddDefaultSort(columns, type, 'gpu'));

    return ({
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        columns: computedColumns.value,
        syncServer: false,
        fetchInterval: 30000,
        fetch: async (filter) => {
            const data = await gpusService.getByNodeIdAndPodGroupId(props.nodeId, props.podGroupId, props.deployment);
            return {data};
        },
        options: {
            noId: true,
            noSelection: true,
            noSearch: true,
        },
    })
};

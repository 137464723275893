import apiClient from "./api-client";
import { Event } from "../types/event";

class EventService {
    async getByJobId(clusterUUID: string, jobId: string): Promise<any> {
        return await this.getEventByResourceType(clusterUUID, jobId, "jobs");
    }

    async getByDeploymentId(clusterUUID: string, deploymentId: string): Promise<any> {
        return await this.getEventByResourceType(clusterUUID, deploymentId, "deployments");
    }

    async getEventByResourceType(clusterUUID: string, resourceId: string, resourceType: string): Promise<any> {
        return await apiClient.asyncRequestWithResponse<Array<Event>>(
            `v1/k8s/clusters/${clusterUUID}/${resourceType}/${resourceId}/events`,
            "GET",
        );
    }
}

const eventService = new EventService();
export default eventService;

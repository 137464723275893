
import getMemory from "@/core-ui/helpers/memory-formats";
import { defineComponent } from "vue";

export default defineComponent({
    props: { value: [Number, String] },
    setup: (props) => ({
        get memory() {
            if (props.value == undefined || props.value == "-") {
                return "-";
            }
            return getMemory(props.value || 0);
        },
    }),
});

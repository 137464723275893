
import { defineComponent, PropType } from "vue";
import Table from "./data-grid/components/Table.vue";
import Popper from "./Popper.vue";
import RaTableCell from "./data-grid/components/RaTableCell.vue";
import Loading from "./Loading.vue";
import { isLoading } from "./data-grid/compositions";
import { getCellProps } from "./data-grid/utils";

import { useRouter } from "vue-router";

export default defineComponent({
    components: { Table, RaTableCell, Loading, Popper },
    props: {
        dataCache: Object as PropType<any>,
        dataMeta: Array,
        dataId: [String, Number],
        text: String,
        link: String,
        target: String,
        reload: Boolean,
    },
    setup(props) {
        const router = useRouter();

        function getProjectFilter(link: string): string {
            let basePath = "/projects";
            const searchParams = new URLSearchParams(link.split("?")[1]);

            const query = searchParams.get("query");
            if (!query) return basePath;

            const projectName = query.split(":")[1]?.trim();
            if (!projectName) return basePath;

            const columnFilters = `[{ "term": "${projectName}", "name": "project-name" }]`;
            return `${basePath}?columnFilter=${columnFilters}`;
        }

        const self = {
            getCellProps,
            get model(): any {
                return (props.dataCache as any).get(props.dataId);
            },

            get isData() {
                return !!self.model;
            },

            get isLoading() {
                return isLoading((props.dataCache as any).state);
            },

            redirectTo() {
                if (!props.link) {
                    console.error("Link is missing!")
                    return;
                };

                if (props.link.includes("/projects")) {
                    const redirectRoute = getProjectFilter(props.link);
                    router.push(redirectRoute);
                } else {
                    router.push(props.link);
                }
            },
        };
        return self;
    },
});

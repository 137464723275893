import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["raicon-close", _ctx.$style.closeBtn]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.container)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}
import clustersStore from "@/stores/clusters-store";

export type ClusterUUIDKey = {
    key: string;
    dataKey: string;
    clusterUUID: string;
};

/**
 * Many Data in our application depends on active ClusterUUID. so the target of this composition is to expose and reactive to the changes in the common keys
 *
 * @export
 * @param {{key: string}} props
 * @returns {ClusterUUIDKey}
 */
export function useClusterUUIDKey(props: { key: string }): ClusterUUIDKey {
    return {
        get key() {
            return props.key;
        },
        get dataKey() {
            return `${props.key}-${this.clusterUUID}`;
        },
        get clusterUUID() {
            return clustersStore.state.activeClusterUUID;
        },
    };
}

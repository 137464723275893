import { Axis, AxisTypes } from "../types/chart";
import * as echarts from "echarts";
import { ChartData, SeriesMeta, ChartUnifyData, ChartSplitData, ChartValue } from "../types/chart";
import getMemory from "@/core-ui/helpers/memory-formats";

type EAxis = echarts.XAXisComponentOption | echarts.YAXisComponentOption;

const DAY = 24 * 60 * 60 * 1000;

function doubleDigit(num: number) {
    if (num > 9) return num;
    return "0" + num;
}

export function dateFormat(value: number) {
    const date = new Date(value);
    // added '+ 1' because month starts from 0 in js. 
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function timeFormat(value: number) {
    const date = new Date(value);
    return `${doubleDigit(date.getHours())}:${doubleDigit(date.getMinutes())}`;
}

type Selected = Record<string, boolean>;

export function resetSelect(selected: Selected, data: any) {
    Object.keys(selected).forEach((k) => data[k] || delete selected[k]);
    Object.keys(data).forEach((k) => selected[k] || (selected[k] = false));
}

export function initSelect(selected: Selected, def?: any) {
    Object.keys(selected).forEach((k) => (selected[k] = false));
    Object.assign(selected, def);
}

export function getDateFormatter(range: number) {
    return range < DAY * 1.5 ? timeFormat : dateFormat;
}

export function isEmpty(value: any) {
    return (
        value === undefined ||
        value === null ||
        (Array.isArray(value) && !value.length) ||
        (typeof value == "object" && Object.values(value).every((v: any) => !v.length))
    );
}

export function axisMap(a: Axis, min?: ChartValue, max?: ChartValue): EAxis {
    const axis: EAxis = { axisLabel: {} };
    switch (a.type) {
        case AxisTypes.Time:
            axis.type = "time";
            axis.axisLabel!.formatter = {
                year: "{yyyy}",
                month: "{MMM}",
                day: "{d}/{M}",
                hour: "{HH}:{mm}",
                minute: "{HH}:{mm}",
                second: "{HH}:{mm}:{ss}",
                millisecond: "{hh}:{mm}:{ss}", //  {SSS} don't show millisecond
                //none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
            };
            // using echart dynamic formatter
            // axis.axisLabel!.formatter = (
            //     typeof min == "number" &&
            //     typeof max == "number" &&
            //     ((max - min) < DAY * 1.5)) ?
            //     timeFormat
            //     :
            //     dateFormat;
            break;
        case AxisTypes.Percentage:
            axis.type = "value";
            axis.min = 0;
            axis.max = 100;
            axis.boundaryGap = [0, "0%"];
            axis.axisLabel!.formatter = "{value}%";
            break;
        case AxisTypes.Value:
            axis.type = "value";
            //axis.boundaryGap = [0, '100%'];
            break;
        case AxisTypes.Memory:
            axis.type = "value";
            axis.min = 0;
            axis.max = Math.max(1024 * 10, (max as any) || 0);
            axis.axisLabel!.formatter = getMemory;
    }

    if (a.format) {
        // todo convert format
        axis.axisLabel!.formatter = a.format;
    }

    return axis;
}

export function mapUnifyDataToSplit(data: ChartUnifyData): ChartSplitData {
    const result: ChartSplitData = {};

    for (const { x, ...rest } of data) {
        for (const [k, y] of Object.entries(rest)) {
            if (!result[k]) {
                result[k] = [];
            }
            result[k].push([x, y]);
        }
    }
    return result;
}

export function mapSeries(
    series: SeriesMeta | ((data: ChartData) => SeriesMeta),
    data: ChartData = [],
): echarts.SeriesOption[] {
    if (typeof series == "function") {
        series = series(data);
    }
    if (Array.isArray(data)) {
        data = mapUnifyDataToSplit(data);
    }

    return Object.entries(series).map(([k, { label }]) => ({
        name: label,
        data: (data as any)[k],
    }));
}

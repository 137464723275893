import { UsingCacheFetch } from "@/core-ui/data-grid/compositions/UsingCatchFetch";

export type ChartValue = string | number;
export type ChartGrid = {
    x: number;
    y: number;
    x2: number;
    y2: number;
};

export enum ChartTypes {
    Line = "line",
    Counter = "counter",
    Percentage = "percentage",
}

export enum AxisTypes {
    Value = "value",
    Time = "time",
    Percentage = "percentage",
    Memory = "memory",
}

type Formatter = (value: ChartValue, min?: ChartValue, max?: ChartValue) => string;
type FormatterTypes = "date";

export type CommonChart = Chart<ChartData, CommonChartMeta>;
export type PercentageChart = Chart<number, PercentageChartMeta>;

export type Chart<D, M> = {
    // temporary filed
    size?: string;
    label?: string;
    cache: UsingCacheFetch<D>;
    meta: M;
};

export type ChartData = ChartUnifyData | ChartSplitData;

export type ChartUnifyData = {
    x: ChartValue;
    [key: string]: ChartValue;
}[];

export type ChartSplitData = {
    // every key is an array of [x,y]
    [key: string]: [ChartValue, ChartValue][];
};

export type SeriesMeta = {
    [key: string]: {
        label: string;
        groupId?: string;
        color?: string;
        order?: number;
    };
};

export type Axis = {
    label?: string;
    type: AxisTypes;
    format?: Formatter | FormatterTypes | string;
};

export type CommonChartMeta = {
    type: ChartTypes;
    x?: Axis;
    y?: Axis;
    grid?: Partial<ChartGrid>;
    selected?: Record<string, boolean>;
    series: SeriesMeta | ((data: ChartData) => SeriesMeta);
};

export type PercentageChartMeta = {
    type: ChartTypes.Percentage;
    color?: string;
};

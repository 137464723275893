
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import { useDataGridModel } from "@/core-ui/data-grid/compositions";
import { createModelProps } from "@/models/pods.model";
import { useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import { defineComponent } from "vue";

export default defineComponent({
    components: { DataGrid },
    props: {
        nodeId: String,
        podGroupId: String,
        deploymentId: String,
    },
    setup(props) {
        const model = useDataGridModel(
            createModelProps(
                useClusterUUIDKey({
                    get key() {
                        return `pods-${props.nodeId}-${props.podGroupId}}-${props.deploymentId}`;
                    },
                }),
                "onlyData",
                {
                    get nodeId() {
                        return props.nodeId;
                    },
                    get podGroupId() {
                        return props.podGroupId;
                    },
                    get deploymentId() {
                        return props.deploymentId;
                    },
                },
            ),
        );

        return {
            model,
        };
    },
});


import { defineComponent } from "vue";
import Chart from "./Chart.vue";

export default defineComponent({
    components: { Chart },
    props: {
        charts: Object,
    },
});

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b586664"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["transform", "height", "width", "position"]
const _hoisted_2 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: {"height":"100%","width":"100%"},
      ref: "referenceRef",
      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnterRef && _ctx.onMouseEnterRef(...args))),
      onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeaveRef && _ctx.onMouseLeaveRef(...args)))
    }, [
      _renderSlot(_ctx.$slots, "reference", {}, undefined, true)
    ], 544),
    (_openBlock(), _createBlock(_Teleport, { to: "#teleport-target" }, [
      (_ctx.showPopper)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: "popupRef",
            class: _normalizeClass(["popup", _ctx.l.arrowDirection == 180 ? 'bottom' : 'top']),
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
            style: _normalizeStyle({ left: `${_ctx.l.pop.x}px`, top: `${_ctx.l.pop.y}px` }),
            onMouseenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseEnterPopup && _ctx.onMouseEnterPopup(...args))),
            onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeavePopup && _ctx.onMouseLeavePopup(...args)))
          }, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 38))
        : _createCommentVNode("", true),
      (_ctx.showPopper)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 1,
            transform: `rotate(${_ctx.l.arrowDirection})`,
            height: _ctx.arrowHeight,
            width: _ctx.arrowWidth,
            class: "arrow",
            position: _ctx.l.arrowDirection,
            style: _normalizeStyle({ left: `${_ctx.l.arrow.x}px`, top: `${_ctx.l.arrow.y}px` })
          }, [
            _createElementVNode("path", {
              class: "arrow-path",
              d: `M0,${_ctx.arrowHeight} L${_ctx.arrowWidth / 2},0 L${_ctx.arrowWidth},${_ctx.arrowHeight}`
            }, null, 8, _hoisted_2)
          ], 12, _hoisted_1))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}
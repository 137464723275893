import { LabelType } from "@/types/label-type";
import {AddDefaultSort, createModelMeta, DataGridModelProps} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import StatusLabel from "@/core-ui/StatusLabel.vue";
import { ClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import podsService from "@/services/pods-service";
import {computed} from "vue";

const stringFormat = (v: string) => v || "-";

export const columns: DataGridColumn[] = [
    {
        key: "name",
        label: "Pod Name",
        dataKey: "name",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 280,
            },
        },
    },
    {
        key: "status",
        label: "Status",
        dataKey: "status",
        reverseKey: "status",
        sortable: true,
        searchable: true,
        display: {
            component: StatusLabel,
            table: {
                width: 150,
            },
        },
    },
    {
        key: "pod-id",
        label: "Pod ID",
        dataKey: "podId",
        primary: true,
        searchable: true,
        dataTransform: stringFormat,
        display: {
            table: {
                width: 200,
                hidden: true,
            },
        },
    },
    // {
    // 	key: 'user',
    // 	label: 'User',
    // 	dataKey: 'user',
    // 	sortable: true,
    // 	searchable: true,
    // 	display: {
    // 		table: {
    // 			width: 120,
    // 			hidden: true
    // 		}
    // 	}
    // },
    // {
    // 	key: 'project',
    // 	label: 'Project',
    // 	dataKey: 'project',
    // 	searchable: true,
    // 	sortable: true,
    // 	display: {
    // 		table: {
    // 			width: 180,
    // 			hidden: true
    // 		}
    // 	}
    // },
    // {
    // 	key: 'gpus',
    // 	label: 'GPUs',
    // 	dataKey: 'requested_gpus',
    // 	sortable: true,
    // 	dataTransform: numeric,
    // 	display: {
    // 		table: {
    // 			width: 150,
    // 			alignedRight: true
    // 		}
    // 	}
    // },
    // {
    // 	key: 'node',
    // 	label: 'Node',
    // 	dataKey: 'nodeId',
    // 	searchable: true,
    // 	display: {
    // 		table: {
    // 			width: 200,
    // 			hidden: true,
    // 		}
    // 	}
    // },
    // {
    // 	key: 'runtime',
    // 	label: 'Total Run Time',
    // 	dataKey: 'totalRuntime',
    // 	sortable: true,
    // 	dataTransform: durationFormat2,
    // 	display: {
    // 		table: {
    // 			width: 220,
    // 		}
    // 	}
    // },
    // 
    // run-4827 - ui fix - we don't show this column anymore.
    // {
    //     key: "image",
    //     label: "Image",
    //     dataKey: "imageName",
    //     sortable: true,
    //     display: {
    //         table: {
    //             width: 160,
    //             hidden: true,
    //         },
    //     },
    // },
    {
        key: "node",
        label: "Node",
        dataKey: "nodeId",
        sortable: true,
        display: {
            table: {
                width: 160,
                hidden: true,
            },
        },
    },
    {
        key: "nodePool",
        label: "Node Pool",
        dataKey: "nodePool",
        sortable: true,
        display: {
            table: {
                width: 160,
                hidden: true,
            },
        },
    },
    {
        key: "created",
        label: "Creation Time",
        dataKey: "created",
        sortable: true,
        dataTransform: (str) => dateFormat(Number(str)),
        display: {
            table: {
                width: 160,
            },
        },
    },
    {
        key: "started",
        label: "Started",
        dataKey: "started",
        sortable: true,
        dataTransform: (str) => dateFormat(Number(str)),
        display: {
            table: {
                width: 160,
            },
        },
    },
    {
        key: "completion",
        label: "Completion Time",
        dataKey: "completed",
        dataTransform: (str) => (str ? dateFormat(Number(str)) : "-"),
        display: {
            table: {
                width: 160,
            },
        },
    },
    // {
    // 	key: 'type',
    // 	label: 'Type',
    // 	dataKey: 'podType',
    // 	display: {
    // 		table: {
    // 			width: 120
    // 		}
    // 	}
    // }
];

export interface Pod {
    clusterId: string;
    created: number;
    started: number;
    completed: number;
    name: string;
    podType: string;
    nodeId: string;
    nodePool: string;
    podId: string;
    project: string;
    status: LabelType;
    imageName: string;
    user: string;
    dynamicData: {
        [key: string]: number | string | { value: string; type: string };
    };
    training_lib: string;
    totalRuntime: number;
    totalWaitTime: number;

    [key: string]: any;
}

export type ModalType = Pod;
const type = "pod";
export const meta = createModelMeta<ModalType>({
    icon: `raicon-${type}`,
    type,
    display: (item) => item.name,
});

export const createModelProps = (
    props: ClusterUUIDKey,
    variant: "page" | "onlyData",
    preFilter: { nodeId?: string; podGroupId?: string; deploymentId?: string } = {},
): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => {
        return AddDefaultSort(columns, type, 'status')
    });
    return {
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey;
        },
        meta,
        get columns() {
            return computedColumns.value;
        },
        fetchInterval: 10000,
        fetch: async () => {
            const data = await podsService.get(preFilter.podGroupId!, preFilter.deploymentId!);
            return { data } as any;
        },
        options:
            variant == "onlyData" &&
            ({
                noSelection: true,
            } as any),
    };
};

import { UsingCacheFetch } from "@/core-ui/data-grid/compositions";
import { ChartData, CommonChartMeta, ChartValue, AxisTypes } from "../types/chart";
import { mapSeries, axisMap } from "../utils";

type Props = {
    cache: UsingCacheFetch<ChartData>;
    meta: CommonChartMeta;
};

export class Range {
    min: ChartValue = undefined as any;
    max: ChartValue = undefined as any;
    updateRange(n: number) {
        if (!this.min || n < this.min) {
            this.min = n;
        }
        if (!this.max || n > this.max) {
            this.max = n;
        }
    }
}

export function useCommonChart(props: Props) {
    function yRange() {
        const r = new Range();
        // todo categroy value
        const d = props.cache.data;
        if (Array.isArray(d)) {
            d.forEach((d) => {
                for (const key in d) {
                    if (key == "y" || key === "1") {
                        r.updateRange(d[key] as number);
                    }
                }
            });
        } else {
            for (const key in d) {
                d[key].forEach((d) => r.updateRange(Array.isArray(d) ? (d[1] as number) : d));
            }
        }
        return r;
    }

    function xRange() {
        const r = new Range();
        // todo categroy value
        const d = props.cache.data;
        if (Array.isArray(d)) {
            d.forEach((d) => {
                for (const key in d) {
                    if (key == "x" || key === "0") {
                        r.updateRange(d[key] as number);
                    }
                }
            });
        } else {
            for (const key in d) {
                d[key].forEach((d) => r.updateRange(Array.isArray(d) ? (d[0] as number) : d));
            }
        }
        return r;
    }

    return {
        get grid() {
            return props.meta.grid;
        },

        get selected() {
            return props.meta.selected;
        },

        get xAxis() {
            const { min, max } = xRange();
            return axisMap(props.meta.x!, min, max);
        },
        get yAxis() {
            const { min, max } = yRange();
            return axisMap(props.meta.y!, min, max);
        },
        get tooltip() {
            return {
                trigger: "axis",
                formatter: function (params: any) {
                    return params
                        .map(
                            (row: any) =>
                                `${row.marker} ${row.seriesName}: ${row.value[1]}${
                                    props.meta.x!.type == AxisTypes.Percentage ? "%" : ""
                                }`,
                        )
                        .join("<br>");
                },
            };
        },
        get series() {
            return mapSeries(props.meta.series, props.cache.data);
        },

        toggleSelect(name: string) {
            if (props.meta.selected) {
                props.meta.selected[name] = !props.meta.selected[name];
            }
        },
    };
}

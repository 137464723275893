import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.progressLabel)
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.progress),
      style: _normalizeStyle({ width: _ctx.percentage })
    }, null, 6),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.$style.text)
    }, _toDisplayString(_ctx.displayValue), 3)
  ], 2))
}
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b960ac4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "target"]
const _hoisted_2 = {
  key: 0,
  style: {"border":"unset"}
}
const _hoisted_3 = { style: {"padding-right":"32px"} }
const _hoisted_4 = { style: {"min-width":"150px"} }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RaTableCell = _resolveComponent("RaTableCell")!
  const _component_Table = _resolveComponent("Table")!
  const _component_loading = _resolveComponent("loading")!
  const _component_Popper = _resolveComponent("Popper")!

  return (_openBlock(), _createBlock(_component_Popper, null, {
    reference: _withCtx(() => [
      (_ctx.reload)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: _normalizeClass(["text", { loading: _ctx.isLoading && !_ctx.isData }]),
            href: _ctx.link,
            target: _ctx.target
          }, _toDisplayString(_ctx.text), 11, _hoisted_1))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            class: _normalizeClass(["text", { loading: _ctx.isLoading && !_ctx.isData }]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.redirectTo && _ctx.redirectTo(...args)), ["stop"]))
          }, _toDisplayString(_ctx.text), 3))
    ]),
    default: _withCtx(() => [
      (_ctx.isData)
        ? (_openBlock(), _createBlock(_component_Table, {
            key: 0,
            flex: false
          }, {
            body: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataMeta, (col) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: col.key
                }, [
                  (!col.hiddenEmpty || !!_ctx.model[col.dataKey])
                    ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
                        _createElementVNode("td", _hoisted_3, _toDisplayString(col.label), 1),
                        _createElementVNode("td", _hoisted_4, [
                          (col.component)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(col.component), _normalizeProps(_mergeProps({ key: 0 }, _ctx.getCellProps(col, _ctx.model))), null, 16))
                            : (_openBlock(), _createBlock(_component_RaTableCell, {
                                key: 1,
                                value: _ctx.model[col.dataKey],
                                row: _ctx.model,
                                column: col
                              }, null, 8, ["value", "row", "column"]))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          }))
        : (_ctx.isLoading && !_ctx.isData)
          ? (_openBlock(), _createBlock(_component_loading, {
              key: 1,
              style: {"border-radius":"4px","overflow":"hidden"}
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, "Not Found"))
    ]),
    _: 1
  }))
}
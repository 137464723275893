
import * as echarts from "echarts";
import "echarts/lib/chart/pie";
import { UsingCacheFetch } from "../data-grid/compositions";
import { Options, Vue } from "vue-class-component";

const BASIC_GAUGE_SERIES = (myChart: any, value: any, c1: any, c2: any) => ({
    series: [
        {
            type: "pie",
            radius: ["57.5%", "62.5%"],
            avoidLabelOverlap: false,
            color: "#0001",
            label: {
                show: false,
                position: "center",
            },
            labelLine: {
                show: false,
            },
            animation: false,
            data: [{ value: 1 }],
        },
        {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: "center",
            },

            labelLine: {
                show: false,
            },

            data: [{ value }, { value: 100 - value }],
            itemStyle: {
                normal: {
                    color: function (args: any) {
                        if (args.dataIndex != 0) return "#0000";

                        return {
                            type: "radial",
                            x: myChart.getWidth() / 2, // calculate x
                            y: myChart.getHeight() / 2, // calculate y
                            r: myChart.getHeight() / 2,
                            colorStops: [
                                {
                                    offset: 0.7,
                                    color: c1,
                                },
                                {
                                    offset: 1,
                                    color: c2,
                                },
                            ],
                            global: true,
                        };
                    },
                },
            },
        },
    ],
});

@Options({
    watch: {
        options() {
            this.chart.setOption(this.options, true);
        },
    },
    props: {
        cache: Object,
        meta: Object,
    },
})
export default class PercentageChart extends Vue {
    cache!: UsingCacheFetch<any>;
    meta!: any;

    chart: any = null;

    mounted() {
        this.chart = echarts.init(this.$refs.gauge as any);
        this.chart.setOption(this.options);
        this.resize = this.resize.bind(this);
        window.addEventListener("resize", this.resize);
    }

    resize() {
        this.chart.resize();
    }

    distroyed() {
        window.removeEventListener("resize", this.resize);
    }

    get displayNumber() {
        const val = this.cache.data || 0;
        return Number.isInteger(val) ? val : val.toFixed(2);
    }

    get options() {
        return BASIC_GAUGE_SERIES(this.chart, this.cache.data || 0, "#0090c5", "#008fc5");
    }
}

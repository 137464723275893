
import Dashboard from "@/core-ui/charts/Dashboard.vue";
import { useCacheFetch } from "@/core-ui/data-grid/compositions";
import { ChartTypes, AxisTypes, CommonChart } from "@/core-ui/charts/types/chart";
import jobsService from "@/services/jobs-service";
import { defineComponent, watch, ref, computed } from "vue";
import { Job } from "@/types/job";
import * as prom from "@/core-ui/helpers/prometheus";
import LoadingDataFailure from "@/core-ui/data-grid/components/LoadingDataFailure.vue";
import StatusGannt from "./StatusGannt.vue";
import TimeZoom from "./TimeZoom.vue";
import { initSelect, resetSelect } from "@/core-ui/charts/utils";

function jobRange(job: Job): [number, number, number] {
    const start = prom.toDate(job.creationTime);
    let end: Date;
    // when the complition time is zero (not define) calculate it by totalRuntime and totalWaitTime
    if (job.completionTime == "0") {
        end = prom.toDate(+start! + Number(job.totalRuntime) * 1000 + Number(job.totalWaitTime) * 1000)!;
    } else {
        end = prom.toDate(job.completionTime || Date.now())!;
    }
    const step = prom.getStep(+start!, +end!);
    //console.log(start, end);
    return [start as any, end as any, step];
}

export default defineComponent({
    components: { Dashboard, LoadingDataFailure, StatusGannt, TimeZoom },
    props: { job: Object },

    setup: (props: any) => {
        const syncMethod = {
            method: "Polling" as any,
            interval: 5000,
            stopOnFailure: true,
        };

        const selectedGPUs = {} as any;
        const start = ref(0);
        const end = ref(0);
        const from = ref(0);
        const to = ref(0);
        const dirty = ref(false);
        const step = computed(() => prom.getStep(from.value, to.value));

        function update() {
            const r = jobRange(props.job);
            start.value = +r[0];
            end.value = +r[1];
            if (!dirty.value) {
                from.value = +r[0];
                to.value = +r[1];
            }
        }

        function getRange() {
            update();
            return [from.value, to.value, step.value] as [number, number, number];
        }

        function key() {
            return props.job.podGroupId + (dirty.value ? from.value + to.value : "");
        }

        watch(
            () => props.job?.podGroupId,
            () => {
                dirty.value = false;
                // init the selected gpus
                initSelect(selectedGPUs, { average: true });
                update();
            },
            { immediate: true },
        );

        const caches = {
            jobPhases: useCacheFetch({
                get key() {
                    return props.job.podGroupId;
                },
                fetch: () => {
                    const params = [start.value, end.value, prom.getStep(start.value, end.value)] as [
                        number,
                        number,
                        number,
                    ];
                    return jobsService.metrics.getJobPhase(props.job.podGroupId, ...params).then((d) => [
                        {
                            name: "Running",
                            style: "fill: url(#running); rx: 1px",
                            timespans: d.running,
                        },
                        {
                            name: "Pending",
                            style: "fill: url(#pending); rx: 1px",
                            timespans: d.pending,
                        },
                        {
                            name: "Suspended",
                            style: "fill: url(#suspended); rx: 1px",
                            timespans: d.suspended,
                        },
                        {
                            name: "Swapped",
                            style: "fill: url(#swapped); rx: 1px",
                            timespans: d.swapped,
                        },
                    ]);
                },
            }),

            gpuUtils: useCacheFetch({
                get key() {
                    return key();
                },
                fetch: () => {
                    const params = getRange();
                    return jobsService.metrics.getGPUsUtils(props.job.podGroupId, ...params).then((data: any) => {
                        resetSelect(selectedGPUs, data);
                        const normalizeFunc = prom.normalizeDataset(...params);
                        return Object.fromEntries(
                            Object.entries(data).map(([k, v]: any) => [k, normalizeFunc(v.map(prom.prepareRangeRow))]),
                        );
                    });
                },
                syncMethod,
            }),

            gpuMemoryUtils: useCacheFetch({
                get key() {
                    return props.job.podGroupId;
                },
                fetch: () => {
                    const params = getRange();
                    return jobsService.metrics.getGpusMemoryUtils(props.job.podGroupId, ...params).then((data: any) => ({
                        utils: prom.normalizeDataset(...params)(prom.metricsFirstValues(data)),
                    }));
                },
                syncMethod,
            }),

            cpuUtils: useCacheFetch({
                get key() {
                    return key();
                },
                fetch: () => {
                    const params = getRange();
                    return jobsService.metrics.getCPUsUtils(props.job.podGroupId, ...params).then((data: any) => ({
                        utils: prom.normalizeDataset(...params)(prom.metricsFirstValues(data)),
                    }));
                },
                syncMethod,
            }),

            memoryUtils: useCacheFetch({
                get key() {
                    return key();
                },
                fetch: () => {
                    const params = getRange();
                    return jobsService.metrics.getMemoryUtils(props.job.podGroupId, ...params).then((data: any) => ({
                        utils: prom.normalizeDataset(...params)(prom.metricsFirstValues(data)),
                    }));
                },
                syncMethod,
            }),
        };

        const gpuUtil: CommonChart = {
            label: "GPU Utilization",
            cache: caches.gpuUtils,
            meta: {
                type: ChartTypes.Line,
                x: { type: AxisTypes.Time },
                y: { type: AxisTypes.Percentage },
                selected: selectedGPUs,
                series: (d) => Object.fromEntries(Object.keys(d).map((k) => [k, { label: k }])),
            },
        };

        const gpuMemoryUtil: CommonChart = {
            label: "GPU Memory Utilization",
            cache: caches.gpuMemoryUtils,
            meta: {
                type: ChartTypes.Line,
                grid: { x: 80 },
                x: { type: AxisTypes.Time },
                y: { type: AxisTypes.Memory },
                series: {
                    utils: { label: "Utilization" },
                },
            },
        };

        const cpuUtil = {
            label: "CPU Utilization",
            cache: caches.cpuUtils,

            meta: {
                type: ChartTypes.Line,
                x: { type: AxisTypes.Time },
                y: { type: AxisTypes.Value },
                series: {
                    utils: { label: "Utilization" },
                },
            },
        };
        const memoryUtil = {
            label: "CPU Memory Utilization",
            cache: caches.memoryUtils,
            meta: {
                type: ChartTypes.Line,
                grid: { x: 80 },
                x: { type: AxisTypes.Time },
                y: { type: AxisTypes.Memory },
                series: {
                    utils: { label: "Utilization" },
                },
            },
        };

        // todo
        caches.jobPhases.fetch();

        return {
            refresh() {
                Object.values(caches).forEach((c) => c.refresh());
            },
            get showDataFailure() {
                return Object.values(caches).some((c) => c.state == "failure");
            },
            from,
            to,
            start,
            end,
            dirty,
            jobPhasesCache: caches.jobPhases,
            gpu: {
                gpuUtil,
            },
            gpuMemory: {
                gpuMemoryUtil,
            },
            cpu: {
                cpuUtil,
            },
            memory: {
                memoryUtil,
            },
        };
    },
});

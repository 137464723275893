import {AddDefaultSort, createModelMeta, DataGridModelProps} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { Event } from "@/types/event";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import eventService from "@/services/events-service";
import {computed} from "vue";

export const columns: DataGridColumn[] = [
    {
        key: "id",
        label: "ID",
        dataKey: "id",
        primary: true,
        display: null,
    },
    {
        key: "creationTimestamp",
        label: "Timestamp",
        dataKey: "creationTimestamp",
        sortable: true,
        searchable: true,
        dataTransform: (str) => dateFormat(Number(str)),

        display: {
            table: {
                width: 170,
            },
        },
    },
    {
        key: "source",
        label: "Source",
        dataKey: "involvedObjKind",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 100,
                hidden: true,
            },
        },
    },
    {
        key: "type",
        label: "Type",
        dataKey: "type",
        sortable: true,
        searchable: false,
        display: {
            table: {
                width: 100,
                hidden: true,
            },
        },
    },
    {
        key: "reason",
        label: "Event",
        dataKey: "reason",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 150,
            },
        },
    },
    {
        key: "message",
        label: "Details",
        dataKey: "message",
        sortable: true,
        searchable: true,
        display: {
            table: {
                width: 555,
                multipleLine: true,
            },
        },
    },
];

export type ModalType = Event;
const type = "event";
export const meta = createModelMeta<ModalType>({
    icon: `raicon-${type}`,
    type,
    display: (item) => item.message,
});

export const createModelProps = (props: {
    jobId: string;
    clusterUuid: string;
    deploymentId: string;
}): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => AddDefaultSort(columns, type));
    return {
        get tableName() {
            return type;
        },
        meta,
        columns: computedColumns.value,
        get dataKey() {
            const idKeyPart = props.deploymentId && props.deploymentId.length > 0 ? props.deploymentId : props.jobId;
            return `${props.clusterUuid}-${idKeyPart}`;
        },
        fetchInterval: 10000,
        fetch: async (filter) => {
            let data;
            if (props.deploymentId && props.deploymentId.length > 0) {
                data = await eventService.getByDeploymentId(props.clusterUuid, props.deploymentId);
            } else {
                data = await eventService.getByJobId(props.clusterUuid, props.jobId);
            }
            return { data };
        },
        options: {
            noSearch: true,
            noSelection: true,
        },
    };
};

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBTabItem = _resolveComponent("MDBTabItem")!
  const _component_MDBTabNav = _resolveComponent("MDBTabNav")!
  const _component_MDBTabs = _resolveComponent("MDBTabs")!

  return (_openBlock(), _createBlock(_component_MDBTabs, {
    modelValue: _ctx.currentTabModel,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTabModel) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_MDBTabNav, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createBlock(_component_MDBTabItem, {
              key: index,
              tabId: tab.title,
              class: _normalizeClass({ disabled: tab.disabled }),
              onClick: ($event: any) => (!tab.disabled && _ctx.$emit('select', tab))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tab.title), 1)
              ]),
              _: 2
            }, 1032, ["tabId", "class", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
import {
    DataGridModelProps,
    createModelMeta,
    useCacheFetch,
    isLazyCacheNeedToRefresh,
    toPopupColumns, AddDefaultSort,
} from "@/core-ui/data-grid/compositions";
import { DataGridColumn } from "@/core-ui/types/column";
import { ClusterUUIDKey, useClusterUUIDKey } from "@/compositions/ClusterUUIDKey";
import projectsService from "@/services/projects-service";
import { toTimePeriod, stringifyTimePeriod } from "@/core-ui/helpers/time-period";
import settingStore, { SettingKeys } from "@/stores/setting-store";
import {AssignedResourcesByNodePools, ProjectType} from "@/types/project";
import { OverQuotaPrioritiesOptions } from "@/helpers/over-quota-priority";
import { dateFormat } from "@/core-ui/helpers/data-formats";
import HoverLink from "@/core-ui/HoverLink.vue";
import { HoverLinkProps } from "@/core-ui/types/hover-link";
import {computed} from "vue";

const overQuotaNames = new Map(OverQuotaPrioritiesOptions.map(({ value, name }) => [value, name]));

export function useProjectLazyCache() {
    const cid = useClusterUUIDKey({ key: "" });
    const expTimestamp = 60000;

    const cache = useCacheFetch<Record<string, Project>>({
        get key() {
            return cid.clusterUUID;
        },
        fetch: () =>
            projectsService.getDesiredQueues().then((d) => Object.fromEntries(d.map((n: Project) => [n.name, n]))),
        defaultData: {},
    });
    return {
        get state() {
            return cache.state;
        },
        get(id: string | number) {
            if (cache.state == "idle") {
                cache.fetch();
            } else {
                if (isLazyCacheNeedToRefresh(cache, expTimestamp)) {
                    cache.refresh();
                }
                return cache.data[id];
            }
        },
    };
}

export function createCompLinkForProject(projectCache: any, reload: boolean = true) {
    return {
        component: HoverLink,
        mapProps: (val: any): HoverLinkProps => ({
            text: val,
            dataId: val,
            link: val && val != "-" ? `/projects?query=project-name: ${val}` : undefined,
            dataCache: projectCache,
            dataMeta: toPopupColumns(columns.filter((c) => !c.filter || c.filter())),
            reload: reload,
        }),
    };
}

export const columns: DataGridColumn[] = [
    {
        key: "id",
        label: "ID",
        dataKey: "id",
        primary: true,
        searchable: true,
        display: null,
    },
    {
        key: "project-name",
        label: "Project Name",
        dataKey: "name",
        searchable: true,
        sortable: true,
        display: {
            popup: { type: "title" },
            table: {
                width: 200,
            },
        },
    },
    {
        key: "department-name",
        label: "Department Name",
        dataKey: "departmentName",
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.DepartmentsUse],
        display: {
            popup: {},
            table: {
                width: 200,
            },
        },
    },
    {
        key: "assigned-gpus",
        label: "Assigned GPUs",
        dataKey: "deservedGpus",
        searchable: true,
        sortable: true,
        filter: () => !settingStore.state.kv[SettingKeys.EnableNodePools],
        display: {
            popup: {},
            table: {
                width: 166,
                alignedRight: true,
            },
        },
    },
    {
        key: "assigned-gpus",
        label: "Assigned GPUs",
        dataKey: "nodePoolsResources",
        dataTransform: (nodePoolsResources: AssignedResourcesByNodePools[]) => {
            if (!nodePoolsResources) return ""
            let nodePoolsGpuSum = 0
            nodePoolsResources.forEach((nodePoolResource) => {
                nodePoolsGpuSum = nodePoolsGpuSum + Number(nodePoolResource.gpu.deserved);
            }, nodePoolsGpuSum)
            return String(nodePoolsGpuSum.toFixed(2))
        },
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.EnableNodePools],
        display: {
            popup: {},
            table: {
                width: 166,
                alignedRight: true,
            },
        },
    },
    {
        key: "assigned-cpu",
        label: "Assigned CPUs (cores)",
        dataKey: "resources",
        dataTransform: (val: any) => {
            return (val.cpu.deserved != null && val.cpu.deserved >= 0) ? val.cpu.deserved / 1000 : "-";
        },
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.CPUResourcesQuota] && !settingStore.state.kv[SettingKeys.EnableNodePools],
        display: {
            popup: {},
            table: {
                width: 166,
                alignedRight: true,
            },
        },
    },
    {
        key: "assigned-cpu",
        label: "Assigned CPUs (cores)",
        dataKey: "nodePoolsResources",
        dataTransform: (nodePoolsResources: AssignedResourcesByNodePools[]) => {
            if (!nodePoolsResources) return ""
            const isUnlimited = nodePoolsResources.every(npr => npr.cpu.deserved === null)
            if(isUnlimited){
                return '-'
            }
            let nodePoolsCpuSum = 0
            nodePoolsResources.forEach((nodePoolResource) => {
                nodePoolsCpuSum = nodePoolsCpuSum + Number(nodePoolResource.cpu.deserved);
            }, nodePoolsCpuSum)
            return String(nodePoolsCpuSum / 1000)
        },
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.CPUResourcesQuota] && settingStore.state.kv[SettingKeys.EnableNodePools],
        display: {
            popup: {},
            table: {
                width: 166,
                alignedRight: true,
            },
        },
    },
    {
        key: "assigned-memory",
        label: "Assigned Memory (MiB)",
        dataKey: "resources",
        dataTransform: (val: any) => {
            return (val.memory.deserved != null && val.memory.deserved >= 0) ? val.memory.deserved : "-";
        },
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.CPUResourcesQuota] && !settingStore.state.kv[SettingKeys.EnableNodePools],
        display: {
            popup: {},
            table: {
                width: 166,
                alignedRight: true,
            },
        },
    },
    {
        key: "assigned-memory",
        label: "Assigned Memory (MiB)",
        dataKey: "nodePoolsResources",
        dataTransform: (nodePoolsResources: AssignedResourcesByNodePools[]) => {
            if (!nodePoolsResources) return ""
            const isUnlimited = nodePoolsResources.every(npr => npr.memory.deserved === null)
            if(isUnlimited){
                return '-'
            }
            let nodePoolsMemorySum = 0
            nodePoolsResources.forEach((nodePoolResource) => {
                nodePoolsMemorySum = nodePoolsMemorySum + Number(nodePoolResource.memory.deserved);
            }, nodePoolsMemorySum)
            return String(nodePoolsMemorySum)
        },
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.CPUResourcesQuota] && settingStore.state.kv[SettingKeys.EnableNodePools],
        display: {
            popup: {},
            table: {
                width: 166,
                alignedRight: true,
            },
        },
    },
    {
        key: "created-at",
        label: "Created",
        dataKey: "createdAt",
        searchable: true,
        sortable: true,
        dataTransform: dateFormat,
        display: {
            label: "Created",
            table: {
                width: 180,
            },
        },
    },
    {
        key: "over-quota-priority",
        label: "Over Quota Priority",
        dataKey: "gpuOverQuotaWeight",
        searchable: true,
        sortable: true,
        filter: () => settingStore.state.kv[SettingKeys.ProjectManualOverQuota],
        dataTransform: (value: number) => overQuotaNames.get(value) || "",
        display: {
            table: {
                width: 180,
            },
        },
    },
    {
        key: "training-node-affinity",
        label: "Training Node Affinity",
        dataKey: "trainNodeAffinity",
        searchable: true,
        sortable: true,
        display: {
            table: {
                width: 300,
            },
        },
    },
    {
        key: "interactive-node-affinity",
        label: "Interactive Node Affinity",
        dataKey: "interactiveNodeAffinity",
        searchable: true,
        sortable: true,
        display: {
            table: {
                width: 300,
            },
        },
    },
    {
        key: "interactive-time-limit",
        label: "Interactive Time Limit",
        dataKey: "interactiveJobTimeLimitSecs",
        searchable: true,
        sortable: true,
        dataTransform: (value) => (value == null ? "none" : stringifyTimePeriod(toTimePeriod(value))),
        display: {
            table: {
                width: 300,
            },
        },
    },
    {
        key: "idle-time-limit",
        label: "Idle Time Limit",
        dataKey: "jobMaxIdleDurationSecs",
        searchable: true,
        sortable: true,
        dataTransform: (value) => (!value ? "none" : stringifyTimePeriod(toTimePeriod(value))),
        display: {
            table: {
                width: 300,
            },
        },
    },
];

export type ModalType = Project;
const type = "project";
export const meta = createModelMeta<ModalType>({
    icon: `raicon-${type}`,
    type,
    display: (item) => item.name,
});

export const createModelProps = (
    props: ClusterUUIDKey,
    filters: { readonly department: string },
): DataGridModelProps<ModalType> => {
    const computedColumns = computed(() => {
        return AddDefaultSort(columns, type, 'name')
    });

    return ({
        get tableName() {
            return type;
        },
        get dataKey() {
            return props.dataKey + filters.department;
        },
        meta,
        columns: computedColumns.value,
        syncServer: false,
        fetchInterval: 10000,
        fetch: async () => {
            const data = (await projectsService.getDesiredQueues()).filter(
                (p: any) => !filters.department || p.departmentName == filters.department,
            );
            return {data};
        },
    })
};

export type Project = ProjectType;


import { computed, defineComponent } from "vue";
import { convertRange, useSize, useSVGDragable } from "../utils";
import { getDateFormatter, timeFormat } from "@/core-ui/charts/utils";

export default defineComponent({
    props: {
        start: Number,
        end: Number,
        from: Number,
        to: Number,
        dirty: Boolean,
    },
    emit: ["update:from", "update:to", "update:dirty"],
    setup(props, ctx) {
        const offsetX = 30;
        const offsetY = 30;

        const container = useSize();

        const c1 = useSVGDragable({ def: [offsetX], min: [offsetX] });
        const c2 = useSVGDragable({});

        function convertX(x: number, invert = false): number {
            const r1 = [props.start, props.end];
            const r2 = [offsetX, container.w - offsetX];
            if (invert) {
                return convertRange(r2 as any, r1 as any, x);
            } else {
                return convertRange(r1 as any, r2 as any, x);
            }
        }

        const c1X = computed(() => (c1.moving ? c1.x : convertX(props.from!)));
        const c2X = computed(() => (c2.moving ? c2.x : convertX(props.to!)));

        return {
            container,
            offsetX,
            offsetY,
            c1,
            c2,
            c1X,
            c2X,
            tickets: computed(() => {
                const count = 8;
                const range = props.end! - props.start!;
                const formatter = getDateFormatter(range);
                const part = range / count;

                return [...Array(count + 1).keys()].map((i) => ({
                    x: convertX(props.start! + part * i),
                    text: formatter(props.start! + part * i),
                }));
            }),

            fromTime: computed(() => {
                let x = props.from!;
                if (c1.moving) {
                    x = convertX(c1.x, true);
                }
                return timeFormat(x);
            }),

            toTime: computed(() => {
                let x = props.to!;
                if (c2.moving) {
                    x = convertX(c2.x, true);
                }
                return timeFormat(x);
            }),

            stop() {
                if (c1.moving || c2.moving) {
                    ctx.emit("update:dirty", true);
                }
                if (c1.moving) {
                    const from = convertX(c1.x, true);
                    ctx.emit("update:from", from);
                    c1.stop();
                }
                if (c2.moving) {
                    const to = convertX(c2.x, true);
                    ctx.emit("update:to", to);
                    c2.stop();
                }
            },

            move(e: any) {
                if (c2X.value > e.layerX) {
                    c1.updateFromSVGMouseEvent(e);
                }
                if (c1X.value < e.layerX && e.layerX < container.w - offsetX) {
                    c2.updateFromSVGMouseEvent(e);
                }
            },
        };
    },
});

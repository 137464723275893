
import { Options, Vue } from "vue-class-component";

@Options({
    emits: ["close"],
    props: {
        open: Boolean,
    },
})
export default class DetailsPanel extends Vue implements Vue {
    open!: boolean;

    $style!: any;

    get classes() {
        return this.open ? [this.$style.detailsPanel, this.$style.open] : this.$style.detailsPanel;
    }
}

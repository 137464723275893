export enum LabelType {
    SUCCESS = "Succeeded",
    ERROR = "Failed",
    RUNNING = "Running",
    COMPLETE = "Complete",
    PROGRESSING = "Progressing",
    PENDING = "Pending",
    DELETED = "Deleted",
    SUSPENDED = "Suspended",
    SWAPPED = "Swapped",
}
export const LabelTypeOrder = {
    [LabelType.COMPLETE]: 1,
    [LabelType.RUNNING]: 2,
    [LabelType.SWAPPED]: 3,
    [LabelType.PENDING]: 4,
    [LabelType.PROGRESSING]: 5,
    [LabelType.SUSPENDED]: 6,
    [LabelType.SUCCESS]: 7,
    [LabelType.DELETED]: 8,
    [LabelType.ERROR]: 9,
};

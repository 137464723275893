import apiClient from "./api-client";
import { NodeAffinityType } from "@/types/project";
import { Project } from "@/models/projects.model";
import clustersStore from "@/stores/clusters-store";

class ProjectsService {
    constructor(private props: { clusterUuid: string }) {}

    async getDesiredQueues(clusterUuid = this.props.clusterUuid) {
        const result = apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${clusterUuid}/projects`, "GET");
        return result.then((p) => p.map(mapProject));
    }

    async createNewProject(project: any, clusterUuid = this.props.clusterUuid) {
        return apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${clusterUuid}/projects`, "POST", project);
    }

    async updateProject(project: any, clusterUuid = this.props.clusterUuid) {
        return apiClient.asyncRequestWithResponse(
            `v1/k8s/clusters/${clusterUuid}/projects/${project.id}`,
            "PUT",
            project,
        );
    }

    async deleteProjects(projects: Array<string | number>, clusterUuid = this.props.clusterUuid) {
        for (const project of projects) {
            await apiClient.request(`v1/k8s/clusters/${clusterUuid}/projects/${project}`, "DELETE");
        }
    }

    async deleteProject(project: Project) {
        if (!project) return;
        return await apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${project.clusterUuid}/projects/${project.id}`, "DELETE");
    }

    async changeDepartment(from: number, to: number, clusterUuid = this.props.clusterUuid) {
        return apiClient.asyncRequestWithResponse(`v1/k8s/clusters/${clusterUuid}/projects/changeDepartment`, "PUT", {
            clusterUuid,
            from,
            to,
        });
    }
}

// todo: this function adding flatten columns but we a need more declarative way to do these changes
function mapProject(p: any) {
    return {
        ...p,
        interactiveNodeAffinity: mapNa(p.nodeAffinity.interactive),
        trainNodeAffinity: mapNa(p.nodeAffinity.train),
    };
}

function mapNa(na: NodeAffinityType) {
    return na.selectedTypes!.map(({ name }) => name).join(", ") || "none";
}

const projectsService = new ProjectsService({
    get clusterUuid() {
        return clustersStore.state.activeClusterUUID;
    },
});
export default projectsService;

import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-458ccd5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-container" }
const _hoisted_2 = { class: "name" }
const _hoisted_3 = {
  key: 1,
  class: "no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProcessBar = _resolveComponent("ProcessBar")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.isUpdating)
        ? (_openBlock(), _createBlock(_component_ProcessBar, {
            key: 0,
            class: "update-process"
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isEmpty)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "No data to show"))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.Comp), {
          key: 2,
          cache: _ctx.cache,
          meta: _ctx.meta
        }, null, 8, ["cache", "meta"]))
  ]))
}
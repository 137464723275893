
import { computed, defineComponent, PropType } from "vue";
import { convertRange, useSize } from "../utils";

type Row = {
    name: string;
    style: string;
    timespans: [number, number][];
};

// const phases = [
//   {
//     name: "Running",
//     style: "fill: url(#running); rx: 4px",
//     timespans: [
//       [0, 5],
//       [8, 10],
//     ],
//   },
//   {
//     name: "Pending",
//     style: "fill: url(#pending); rx: 4px",
//     timespans: [
//       [5, 8],
//       [10, 14],
//     ],
//   },
// ];

export default defineComponent({
    props: {
        data: Array as PropType<Row[]>,
    },
    setup(props) {
        const container = useSize();
        const offset = 64;

        const range = computed(() =>
            props.data?.reduce(
                (range, { timespans }) =>
                    timespans.reduce(
                        ([min, max], [start, end]) => [
                            min === undefined ? start : Math.min(min, start),
                            max === undefined ? end : Math.max(max, end),
                        ],
                        range,
                    ),
                [undefined as any, undefined as any],
            ),
        );
        let scaler = (value: number) => {
            return convertRange(range.value as any, [offset, container.w], value);
        };
        return {
            offset,
            container,
            range,

            calcStartX(value: number) {
                return scaler(value);
            },

            calcWidth(start: number, end: number) {
                return scaler(end) - scaler(start);
            },
        };
    },
});

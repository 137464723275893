import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ELineChart = _resolveComponent("ELineChart")!

  return (_openBlock(), _createBlock(_component_ELineChart, {
    tooltip: _ctx.state.tooltip,
    "x-axis": _ctx.state.xAxis,
    "y-axis": _ctx.state.yAxis,
    grid: _ctx.state.grid,
    selected: _ctx.state.selected,
    series: _ctx.state.series,
    onSelectChange: _ctx.state.toggleSelect
  }, null, 8, ["tooltip", "x-axis", "y-axis", "grid", "selected", "series", "onSelectChange"]))
}
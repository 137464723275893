
import getMemory from "@/core-ui/helpers/memory-formats";
import { defineComponent, PropType } from "vue";

const HUNDRED = 100;
const PERCENTAGE = "%";

export default defineComponent({
    props: {
        value: { types: [String, Number], default: 0 },
        of: { types: [String, Number], default: 100 },
        format: {
            type: String as PropType<"numeric" | "memory">,
            default: "numeric",
        },
    },
    setup(props) {
        const self = {
            get percentage() {
                if (props.value === -1) {
                    return "N/A";
                }

                const val = props.value || 0;
                if (props.of === HUNDRED) {
                    return (Number.isInteger(val) ? val : val.toFixed ? val.toFixed(2) : val) + PERCENTAGE;
                }
                const percentage = (Number(val) / Number(props.of)) * HUNDRED;
                if (percentage > HUNDRED) {
                    return HUNDRED + PERCENTAGE;
                }
                return (Number.isInteger(percentage) ? percentage : percentage.toFixed(2)) + PERCENTAGE;
            },

            get displayValue() {
                if (props.value === -1) {
                    return "N/A";
                }
                if (props.of === HUNDRED) {
                    return self.percentage;
                }
                const num = Number(props.value);
                if (props.format === "memory") {
                    return getMemory(num) + " of " + getMemory(props.of);
                } else {
                    return (Number.isInteger(num) ? num : num.toFixed(2)) + " of " + props.of;
                }
            },
        };
        return self;
    },
});

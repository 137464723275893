import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11311896"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.charts, (val, key) => {
      return (_openBlock(), _createBlock(_component_Chart, {
        style: _normalizeStyle(`min-width: 280px; margin-right:16px;flex: ${val.size || 1}`),
        label: val.label,
        key: key,
        meta: val.meta,
        cache: val.cache
      }, null, 8, ["style", "label", "meta", "cache"]))
    }), 128))
  ]))
}
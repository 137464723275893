
import DataGrid from "@/core-ui/data-grid/components/DataGrid.vue";
import { useDataGridModel } from "@/core-ui/data-grid/compositions";
import { createModelProps } from "@/models/gpus.model";
import { defineComponent } from "vue";

export default defineComponent({
    components: { DataGrid },
    props: {
        nodeId: String,
        podGroupId: String,
        deployment: Object
    },
    setup(props: any) {
        const model = useDataGridModel(
            createModelProps({
                get dataKey() {
                    return `gpus-${props.nodeId}-${props.podGroupId}`;
                },
                get nodeId() {
                    return props.nodeId;
                },
                get podGroupId() {
                    return props.podGroupId;
                },
                get deployment() {
                    return props.deployment;
                },
            }),
        );

        return {
            model,
        };
    },
});

import { numeric } from "./data-formats";

const k = 1024;
const dm = 2;
const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export default function getMemory(bytes: number | string) {
    if (bytes == "-" || bytes === undefined) return "-";
    const val = Number(bytes);
    if (!val || val === 0) return "0 Bytes";
    if (val < 1) return "";

    const i = Math.floor(Math.log(val) / Math.log(k));
    let size = sizes[i];

    // this covers unit size bug (that nvidia has at this point of time).
    // there shouldn't be any PB in the foreseeable future so this should hold for a few years at least
    if (size === "PB")
        size = "GB"
    return parseFloat((val / Math.pow(k, i)).toFixed(dm)) + " " + size;
}

const marker = 1000; // Change to 1000 if required
const decimal = 3; // Change as required
const kiloBytes = marker; // One Kilobyte is 1024 bytes
const megaBytes = marker * marker; // One MB is 1024 KB
const gigaBytes = marker * marker * marker; // One GB is 1024 MB
const teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

export function formatBytes_1000(def = "0") {
    return (bytes: number) => {
        if (bytes == 0 || bytes == undefined || bytes == null) {
            return def;
        }

        let number,
            suffix = "";
        // return bytes if less than a KB
        if (bytes < kiloBytes) {
            number = bytes;
            suffix = " Bytes";
        }
        // return KB if less than a MB
        else if (bytes < megaBytes) {
            number = (bytes / kiloBytes).toFixed(decimal);
            suffix = " KB";
        }
        // return MB if less than a GB
        else if (bytes < gigaBytes) {
            number = (bytes / megaBytes).toFixed(decimal);
            suffix = " MB";
        }
        // return GB if less than a TB
        else {
            number = (bytes / gigaBytes).toFixed(decimal);
            suffix = " GB";
        }

        return numeric(number) + suffix;
    };
}


import { defineComponent, ref, watchEffect } from "vue";

export default defineComponent({
    components: {},
    emits: ["popped"],
    props: {
        disabled: Boolean,
    },
    setup(props, ctx) {
        let hover = false;
        let hoverPopup = false;
        const offset = 8;
        const arrowHeight = 6;
        const arrowWidth = 12;
        const hoverMoreThanX = ref(false);
        const referenceRef = ref<HTMLElement>(null as any);
        const popupRef = ref<HTMLElement>(null as any);

        watchEffect(() => {
            if (hoverMoreThanX.value) {
                ctx.emit("popped");
            }
        });

        const self = {
            referenceRef,
            popupRef,
            arrowHeight,
            arrowWidth,
            get l() {
                const { left, width: w, top, height: h } = referenceRef.value.getBoundingClientRect();

                const ww = document.body.clientWidth;
                const wh = document.body.clientHeight;
                const ph = popupRef.value?.clientHeight || 0;
                const pw = popupRef.value?.clientWidth || 0;

                const topY = top + h + offset;
                const bottomY = top - ph - offset;
                const isBottom = topY + ph > wh;
                const popY = isBottom ? bottomY : topY;
                const arrowY = isBottom ? top - offset : topY - arrowHeight;

                const middle = left + w / 2;
                const arrowX = middle - arrowWidth / 2;
                let popX = middle - pw / 2;
                // todo: check that arrowX is not over the poper content
                popX = Math.max(offset, popX);
                if (popX + pw + offset > ww) {
                    popX -= popX + pw + offset - ww;
                }
                return {
                    pop: { x: popX, y: popY },
                    arrow: { x: arrowX, y: arrowY },
                    arrowDirection: isBottom ? 180 : 0,
                };
            },
            get showPopper() {
                return hoverMoreThanX.value && !props.disabled;
            },
            onMouseEnterRef() {
                hover = true;
                setTimeout(() => hover && (hoverMoreThanX.value = true), 750);
            },
            onMouseLeaveRef() {
                hover = false;
                setTimeout(() => !hover && !hoverPopup && (hoverMoreThanX.value = false), 500);
            },
            onMouseEnterPopup() {
                hoverPopup = true;
            },
            onMouseLeavePopup() {
                hoverPopup = false;
                setTimeout(() => !hover && !hoverPopup && (hoverMoreThanX.value = false), 500);
            },
        };
        return self;
    },
});

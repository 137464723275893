
import { Axis, AxisTypes, CommonChartMeta, ChartData, ChartValue } from "./types/chart";
import { axisMap, mapSeries } from "./utils";
import { UsingCacheFetch } from "../data-grid/compositions";
import ELineChart from "./echart/ELineChart.vue";
import { useCommonChart } from "./compositions/useCommonChart";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    components: { ELineChart },
    props: {
        meta: Object as PropType<CommonChartMeta>,
        cache: Object as PropType<UsingCacheFetch<ChartData>>,
    },
    setup: (props: any) => {
        props.cache.fetch();
        return {
            state: useCommonChart(props),
        };
    },
});
